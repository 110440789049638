// Si empiezan con R son Retenciones
// Si empiezan con CH son Cheques
export const TIPOS = [
    {id: 'EFE', description: 'Efectivo'},
    {id: 'CHF', description: 'Cheques físicos'},
    {id: 'CHE', description: 'Cheques electrónicos'},
    {id: 'CAN', description: 'Compensaciones'},
    {id: 'TRF', description: 'Transferencia Bancaria'},
    {id: 'TRP', description: 'Transferencia Provisoria'},
    {id: 'AEF', description: 'Acreditación Efectivo'},
    {id: 'RSS', description: 'Retención Seguridad Social'},
    {id: 'RIV', description: 'Retención IVA'},
    {id: 'RGA', description: 'Retención Ganancias'},
    {id: 'RIB', description: 'Retención Ing. Brutos'},
    {id: 'SCC', description: 'Saldo en Cuenta Corriente', disabled: true},
    {id: 'CHX', description: 'Cheques', disabled: true},

    {id: 'RCO', description: 'Recibo de Cobranza', disabled: true}, // Item especial para recibos de cobranza amarillo
];
