import React, {useEffect, useState} from 'react';
import {useSnackbar} from "notistack";
import {Paper, Typography} from "@material-ui/core";
import PropTypes from "prop-types";
import Switch from "@material-ui/core/Switch";
import Box from "@material-ui/core/Box";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/es/TextField";

export const MultipleSelect = React.memo((
    {title, all, selected, getData, onChangeAll, onChangeSelected, id, chip, reload}
) => {
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const [data, setData] = useState([]);
    const [selectOpen, setSelectOpen] = useState(false);

    useEffect(() => {
        if (all === false || reload === true) {
            getData(
                success => setData(success.data.result),
                error => enqueueSnackbar(error, {variant: 'error'})
            );
        }
    }, [all, reload]);

    // Filtro las opciones para indicar los seleccionados
    if (selected.length > 0)
        selected = data.filter(_data => selected.includes(_data[id]) > 0);

    return (
        <Box>
            <Paper elevation={1} style={{padding: '0.7rem 1.5rem'}} className={'d-flex align-items-center'}>
                <Box className={'flex-grow-1'}>
                    {all === true ? (
                        <Typography variant={"body2"} color={"textSecondary"} component={"span"}>{title}</Typography>
                    ) : (
                        <Autocomplete
                            multiple
                            fullWidth
                            disableCloseOnSelect
                            limitTags={7}
                            filterSelectedOptions
                            options={data}
                            value={selected}
                            onChange={onChangeSelected}
                            getOptionLabel={(option) => option[chip]}
                            open={selectOpen}
                            onOpen={() => setSelectOpen(true)}
                            onClose={() => setSelectOpen(false)}
                            // defaultValue={[top100Films[13], top100Films[12], top100Films[11]]}
                            renderInput={(params) => (
                                <TextField {...params} variant="standard" label={title} placeholder="ítems"/>
                            )}
                        />
                    )}
                </Box>
                <Box className={'d-inline-block ml-3'}>
                    <Switch
                        checked={all}
                        onChange={() => {
                            onChangeAll(!all);
                            // if (!all === false)
                            //     setSelectOpen(true);
                        }}
                        size={"medium"}
                    />
                    <Typography variant={"caption"}>todos</Typography>
                </Box>
            </Paper>
        </Box>
    )
})

MultipleSelect.defaultProps = {
    reload: false,
    title: 'MultipleSelect',
    height: 350,
    description: 'detalle',
    chip: 'id',
    getData: () => {
    },
    onChangeAll: () => {
    },
    onChangeSelected: () => {
    },
    onCancelSelection: () => {
    }
}

MultipleSelect.propsTypes = {
    title: PropTypes.string,
    height: PropTypes.number,
    getData: PropTypes.func.isRequired,
    onChangeAll: PropTypes.func,
    onChangeSelected: PropTypes.func,
    onCancelSelection: PropTypes.func,
}
