import React, {useContext, useEffect, useState} from "react";
import {MainContext} from "contexts/main.context";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography} from "@material-ui/core";
import {EditRounded, WarningRounded} from "@material-ui/icons";
import {useArticulosService} from "services/hooks";
import {useSnackbar} from "notistack";
import {ScanReceptor} from "components/picking/components/scan.receptor";
import qrIcon from "assets/img/qr.svg";

export const BarcodeWithEdit = ({row}, refreshListFn) => {
    const [barcodeDialogOpen, setBarcodeDialogOpen] = useState(false);
    const context = useContext(MainContext);

    const onClick = (e) => {
        e.stopPropagation();
        setBarcodeDialogOpen(true);
    };

    return <>
        <span>{row.codigobarr}</span>
        {
            (row.codigobarr && ["A", "O"].includes(context.loggedUser.role)) ?
                <IconButton onClick={onClick}>
                    <EditRounded fontSize={"small"}/>
                </IconButton> :
                <></>
        }
        <ArticuloBarcodeDialog
            open={barcodeDialogOpen}
            id={row.id}
            onCancel={() => setBarcodeDialogOpen(false)}
            onConfirm={() => {
                setBarcodeDialogOpen(false);
                refreshListFn();
            }}
        />
    </>
}

export const ArticuloBarcodeDialog = (props) => {
    return (
        <Dialog
            open={props.open}
            onClose={props.onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className='articulo-details-dialog'
        >
            <ArticuloBarcodeDialogContent {...props}/>
        </Dialog>
    );
}

const ArticuloBarcodeDialogContent = ({open, id, onConfirm, onCancel}) => {
    const [articulo, setArticulo] = useState({});
    const [barcode, setBarcode] = useState();
    const [warning, setWarning] = useState({enabled: false, msg: ''});

    const context = useContext(MainContext);
    const ArticulosService = useArticulosService();
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        ArticulosService.getById(
            id,
            null,
            response => {
                setArticulo(response.data.result);
                setBarcode(response.data.result.codigobarr);
            },
            error => enqueueSnackbar(error, {variant: 'error'})
        );
    }, []);

    const onScanCode = code => {
        if (code.length > 20) {
            const offset = 3;
            setBarcode(code.substring(offset, 13 + offset));
            setWarning({enabled: true, msg: `El código leído: ${code} se recortó a 13 caracteres.`});
        } else {
            setBarcode(code);
            setWarning({enabled: false, msg: ''});
        }
    };

    const barcodeAlreadyExists = (idArticulo, barcode) => {
        return new Promise((resolve, reject) => {
            ArticulosService.checkIfBarcodeExists(
                idArticulo,
                barcode,
                response => resolve(response.data.result),
                error => {
                    resolve(true);
                    enqueueSnackbar(error, {variant: 'error'})
                }
            );
        })
    }

    const confirm = async () => {
        const barcodeExists = await barcodeAlreadyExists(id, barcode);
        if (barcodeExists) {
            enqueueSnackbar("El código de barras que quiere ingresar ya existe para otro artículo", {variant: 'error'});
            return false;
        }

        ArticulosService.editBarcode(
            id,
            barcode,
            response => {
                enqueueSnackbar("El código de barras se editó correctamente", {variant: 'success'});
                onConfirm();
            },
            error => enqueueSnackbar(error, {variant: 'error'})
        );
    };

    return (
        <>
            <ScanReceptor onScanCode={onScanCode}/>
            <DialogTitle id="alert-dialog-title">
                <Typography variant={"body1"}>{articulo.id}</Typography>
                <Typography variant={"body2"} color={"textSecondary"}>{articulo.detalle}</Typography>
            </DialogTitle>
            <DialogContent>
                <Typography variant={"caption"} component={"div"} align={"center"} className={'mt-3'}>Código de barras</Typography>
                <Typography
                    variant={"h5"}
                    align={"center"}
                    color={barcode !== articulo.codigobarr ? "primary" : "textPrimary"}
                >
                    {barcode || "Ninguno"}
                </Typography>
                <Typography variant={"caption"} component={"div"} align={"center"}>
                    {barcode !== articulo.codigobarr ? <>(anterior: {articulo.codigobarr ? articulo.codigobarr : 'Ninguno'})</> : ''}
                </Typography>
                <Typography variant={"body2"} component={"div"} align={"center"} color={"textSecondary"} className={'d-flex mt-3'}>
                    {warning.enabled ? <><WarningRounded fontSize={"small"} className={'mr-1'}/>{warning.msg}</> : ''}
                </Typography>
                <img
                    src={qrIcon}
                    width={'135px'}
                    className={'m-auto'}
                    style={{
                        margin: '0 auto',
                        padding: '50px 0 50px 0',
                        display: 'block'
                    }}/>
                <Typography variant={"body2"} color={"textSecondary"} align={"center"}>Puede escanear un código de barras</Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={e => {
                        e.stopPropagation();
                        onCancel();
                    }}
                    color="default"
                >
                    Cerrar
                </Button>
                <Button
                    onClick={e => {
                        e.stopPropagation();
                        confirm();
                    }}
                    color="primary"
                >
                    Confirmar
                </Button>
            </DialogActions>
        </>
    )
}
