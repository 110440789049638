import React, {useContext} from "react";
import PropTypes from 'prop-types';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@material-ui/core";
import {ArticulosSelect, LocalidadesSelect} from "components/estadisticas/settings/table.selects";
import {DateSelects} from "components/estadisticas/settings/date.pickers";
import {
    CalidadesSelect,
    ClasificacionesSelect,
    ClientesSelect,
    EnvasesSelect,
    GruposProveedorSelect,
    LineasSelect,
    ProveedoresSelect,
    RubrosSelect,
    VendedoresSelect
} from "components/estadisticas/settings/multiple.selects";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {MainContext} from "contexts/main.context";

function DialogRow({children}) {
    return <Grid item className={'w-100'}>{children}</Grid>
}

function TabPanel({active, tab, children}) {
    return <div role="tabpanel" hidden={active !== tab} className={'mt-4'}>
        <Box style={{maxWidth: '50rem', margin: '0 auto'}}>
            <Grid container direction={"column"} spacing={2}>
                {active === tab && children}
            </Grid>
        </Box>
    </div>
}

function VentasSettings({
                            open,
                            title,
                            onCancel,
                            cancelText,
                            onConfirm,
                            confirmText,
                            fullScreen,
                            maxWidth,
                            state,
                            dispatch,
                            enabledOnlyTabs,
                            ignoreLocalidades = false,
                            ignoreClasificaciones = false
                        }) {
    const {
        dates, vendedores, localidades, clientes, clasificaciones, articulos, calidades,
        rubros, envases, lineas, proveedores, grupos, subgrupos
    } = state;

    const [activeTab, setActiveTab] = React.useState(0);
    const context = useContext(MainContext);

    const enabledTabs = enabledOnlyTabs || ['generales', 'clientes', 'articulos', 'proveedores'];

    const tabIsEnabled = tabKey => enabledTabs.includes(tabKey);

    const getTabIndex = tabKey => enabledTabs.indexOf(tabKey);

    return (
        <>
            <Dialog
                open={open}
                onClose={onCancel}
                fullScreen={fullScreen}
                fullWidth
                className={'VentasSettingsDialog'}
                classes={{
                    paperFullScreen: 'niquel-dialog-full-screen'
                }}
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <Tabs
                        value={activeTab}
                        onChange={(event, value) => setActiveTab(value)}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                    >
                        {tabIsEnabled("generales") && <Tab label="Generales"/>}
                        {tabIsEnabled("clientes") && <Tab label="Clientes"/>}
                        {tabIsEnabled("articulos") && <Tab label="Artículos"/>}
                        {tabIsEnabled("proveedores") && <Tab label="Proveedores"/>}
                    </Tabs>

                    {tabIsEnabled("generales") &&
                        <TabPanel active={activeTab} tab={getTabIndex("generales")}>
                            <Grid item>
                                <DateSelects state={dates} dispatch={dispatch}/>
                            </Grid>
                            {context.loggedUser.role !== 'V' && (
                                <Grid item>
                                    <VendedoresSelect state={vendedores} dispatch={dispatch}/>
                                </Grid>
                            )}
                            {!ignoreLocalidades &&
                                <Grid item>
                                    <LocalidadesSelect state={localidades} dispatch={dispatch}/>
                                </Grid>
                            }
                        </TabPanel>
                    }

                    {tabIsEnabled("clientes") &&
                        <TabPanel active={activeTab} tab={getTabIndex("clientes")}>
                            <Grid item>
                                <ClientesSelect state={clientes} dispatch={dispatch}/>
                            </Grid>
                            {!ignoreClasificaciones &&
                                <Grid item>
                                    <ClasificacionesSelect state={clasificaciones} dispatch={dispatch}/>
                                </Grid>
                            }
                        </TabPanel>
                    }

                    {tabIsEnabled("articulos") &&
                        <TabPanel active={activeTab} tab={getTabIndex("articulos")}>
                            <Grid item>
                                <ArticulosSelect state={articulos} dispatch={dispatch}/>
                            </Grid>
                            <Grid item>
                                <CalidadesSelect state={calidades} dispatch={dispatch}/>
                            </Grid>
                            <Grid item>
                                <RubrosSelect state={rubros} dispatch={dispatch}/>
                            </Grid>
                            <Grid item>
                                <EnvasesSelect state={envases} dispatch={dispatch}/>
                            </Grid>
                            <Grid item>
                                <LineasSelect state={lineas} dispatch={dispatch}/>
                            </Grid>
                        </TabPanel>
                    }

                    {tabIsEnabled("proveedores") &&
                        <TabPanel active={activeTab} tab={getTabIndex("proveedores")}>
                            <Grid item>
                                <ProveedoresSelect state={proveedores} dispatch={dispatch}/>
                            </Grid>
                            <Grid item>
                                <GruposProveedorSelect state={grupos} dispatch={dispatch} proveedores={proveedores.selected}/>
                            </Grid>
                        </TabPanel>
                    }
                </DialogContent>
                <DialogActions>
                    {onCancel && (
                        <Button onClick={onCancel}>
                            {cancelText}
                        </Button>
                    )}

                    {onConfirm && (
                        <Button onClick={onConfirm} color={"primary"} autoFocus>
                            {confirmText}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </>
    )
}

VentasSettings.propTypes = {
    onChangeSomething: PropTypes.func,
    onChangeDates: PropTypes.func,
    onChangeVendedores: PropTypes.func,
    onChangeClientes: PropTypes.func,
    onChangeClasificacionesClientes: PropTypes.func,
    onChangeArticulos: PropTypes.func,
    onChangeCalidades: PropTypes.func,
    onChangeRubros: PropTypes.func,
    onChangeEnvases: PropTypes.func,
    onChangeLineas: PropTypes.func,
    onChangeProveedores: PropTypes.func,
    onChangeGrupos: PropTypes.func,
    onChangeSubgrupos: PropTypes.func,
    onChangeLocalidades: PropTypes.func,
    initialDates: PropTypes.object,
}

VentasSettings.defaultProps = {
    title: '',
    open: false,
    cancelText: 'Cancelar',
    confirmText: 'Confirmar'
}

export default VentasSettings;
