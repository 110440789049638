import {AccordionSummary, Grid, IconButton, TextField, Tooltip, Typography} from "@material-ui/core";
import {DoneRounded, EditRounded, ExpandMoreRounded, HighlightOffRounded} from "@material-ui/icons";
import {InputPercentage} from "assets/utils";
import CheckCircleOutlineRounded from "@material-ui/icons/CheckCircleOutlineRounded";
import React, {useState} from "react";

export const ObjetivoSummary = ({
                                    idObjetivo,
                                    tipo = '',
                                    nombre = '',
                                    peso = 0,
                                    cantSubObjetivos = 0,
                                    errorState,
                                    onEditPesoGeneral
                                }) => {
    const [editingPeso, setEditingPeso] = useState(false);
    const [editBtnPesoVisible, setEditBtnPesoVisible] = useState(false);
    const [pesoEdited, setPesoEdited] = useState(peso);
    return (
        <AccordionSummary
            expandIcon={<ExpandMoreRounded/>}
            focusVisibleClassName={'bg-white'}
            style={{
                pointerEvents: 'all'
            }}
        >
            <Grid container justifyContent={"space-between"} alignItems={"center"}>
                <Grid item>
                    <Typography>
                        <>
                            {nombre} <Typography variant={"body2"} component={"span"}
                                                 color={"textSecondary"}> - {tipo.toLowerCase()}</Typography>
                        </>
                    </Typography>
                    <Typography variant={"body2"} color={"textSecondary"}>
                        {cantSubObjetivos} subobjetivos
                    </Typography>
                </Grid>
                <Grid container item spacing={7} alignItems={"center"} alignContent={"center"} className={'w-auto'}>
                    <Grid item
                          onMouseEnter={() => setEditBtnPesoVisible(true)}
                          onMouseLeave={() => setEditBtnPesoVisible(false)}
                    >
                        {editingPeso ? (
                            <>
                                <TextField
                                    value={pesoEdited}
                                    variant={"outlined"}
                                    InputProps={{
                                        inputComponent: InputPercentage,
                                        style: {
                                            width: '4.3rem',
                                            height: '2.7rem',
                                            pointerEvents: 'all'
                                        },
                                    }}
                                    onClick={e => e.stopPropagation()}
                                    onChange={e => setPesoEdited(e.target.value)}
                                />
                                <Tooltip title={'Confirmar Peso General'}>
                                    <IconButton
                                        style={{marginLeft: '2px', width: '2.7rem', height: '2.7rem'}}
                                        color={"primary"}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setEditingPeso(false);
                                            peso = pesoEdited;
                                            onEditPesoGeneral(pesoEdited)
                                        }}
                                    >
                                        <DoneRounded fontSize={"small"}/>
                                    </IconButton>
                                </Tooltip>
                            </>
                        ) : (
                            <>
                                <Typography variant={"caption"} color={"textSecondary"} component={"span"}>peso
                                    general</Typography>
                                <Typography component={"span"} className={'ml-1'}>{peso}%</Typography>
                                <Tooltip title={'Editar Peso General'}>
                                    <IconButton
                                        style={{
                                            marginLeft: '2px',
                                            width: '2.7rem',
                                            height: '2.7rem',
                                            visibility: editBtnPesoVisible ? 'visible' : 'hidden'
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setEditingPeso(true);
                                        }}
                                    >
                                        <EditRounded fontSize={"small"}/>
                                    </IconButton>
                                </Tooltip>
                            </>
                        )}
                    </Grid>
                    <Grid item className={'d-flex'}>
                        {errorState === false ? (
                            <Tooltip title={'Sin errores'}>
                                <CheckCircleOutlineRounded style={{width: '1.7rem', height: '1.7rem'}}
                                                           color={"primary"}/>
                            </Tooltip>
                        ) : (
                            <Tooltip title={errorState}>
                                <HighlightOffRounded style={{width: '1.7rem', height: '1.7rem'}} color={"error"}/>
                            </Tooltip>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </AccordionSummary>
    )
}
