import React, {Component} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    MenuItem,
    TextField,
    Typography
} from "@material-ui/core";
import {MastersService} from "services";
import {MainContext} from "contexts/main.context";
import {withSnackbar} from "notistack";

export class PDFTypeDialog extends Component {
    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onCancel}
                fullScreen={this.props.fullScreen}
                fullWidth
                maxWidth={"xs"}
                classes={{
                    paperFullScreen: 'niquel-dialog-full-screen'
                }}
            >
                <PDFTypeDialogContent {...this.props}/>
            </Dialog>
        )
    }
}

// const bonificacionPrepago = {dias: 0, porcentaje: 30};
const bonificacionCero = {dias: 99, porcentaje: 0};

class PDFTypeDialogContent extends Component {
    state = {
        bonificaciones: [bonificacionCero],
        bonificacion: 99
    }

    MastersService = new MastersService(this.context);

    componentDidMount() {
        this.MastersService.getBonificacionesByDiasPago(
            response => {
                let bonificaciones = response.data.result;
                this.setState(prevState => prevState.bonificaciones = prevState.bonificaciones.concat(bonificaciones))
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        )
    }

    render() {
        const {bonificaciones, bonificacion} = this.state;
        const {title, body, onConfirm, onCancel, confirmText = "Confirmar", cancelText = "Cancelar"} = this.props;

        return (
            <React.Fragment>
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" component={'div'}>
                        <Typography variant={"body2"} component={"div"}>
                            {body}
                        </Typography>
                        <TextField
                            select
                            required
                            label="Tipo de bonificación"
                            className='mr-2 select'
                            value={bonificacion}
                            fullWidth
                            onChange={event => {
                                this.setState({bonificacion: event.target.value});
                            }}
                        >
                            {bonificaciones
                                .filter(bonificacion => bonificacion.porcentaje > 0 || bonificacion.dias === 99)
                                .map((bonificacion, index) => (
                                    <MenuItem key={index} value={bonificacion.dias}>
                                        {bonificacion.dias === 99 ?
                                            <>Precio sin bonificar (0%)</> :
                                            bonificacion.dias > 0 ?
                                                <>Precio bonificado por pago hasta {(bonificacion.dias) - 2} días
                                                    ({bonificacion.porcentaje}%)</> :
                                                <>Precio bonificado por PREPAGO ({bonificacion.porcentaje}%)</>
                                        }
                                    </MenuItem>
                                ))
                            }
                        </TextField>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {/*Muestro Cancel*/}
                    {onCancel && (
                        <Button onClick={onCancel}>
                            {cancelText}
                        </Button>
                    )}

                    {/*Muestro Confirm*/}
                    {onConfirm && (
                        <Button
                            onClick={() => onConfirm(this.state.bonificaciones.find(bonificacion => bonificacion.dias === this.state.bonificacion))}
                            color={"primary"}
                            autoFocus>
                            {confirmText}
                        </Button>
                    )}
                </DialogActions>
            </React.Fragment>
        )
    }
}

PDFTypeDialogContent.contextType = MainContext;
PDFTypeDialogContent = withSnackbar(PDFTypeDialogContent);
